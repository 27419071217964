import './style-dictionary/tokens/_STYLE_TOKENS_.css'
import './style/typography.css'
import './App.css'
import { AuctionPage } from './components/AuctionPage'

function App() {
	return (
		<div className="App">
			<AuctionPage />
		</div>
	)
}

export default App
